
import './App.css';
import { Login } from './login';

function App() {
  return (
    <div className="App">

      <Login />
      <p></p>
      <i>I recommend using the Chrome web browser.</i>
    </div>
  );
}

export default App;
